@import './../../assets/sass/typography';
@import './../../assets/sass/box';
@import './../../assets/sass/colors';

html {
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  color: $black;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a:link,
a:visited {
  color: $blue
}

a:hover,
a:active {
  color: darken($blue, 10%);
}

.App {
  font-size: 1rem;
  min-height: 100vh;
  padding-top: 10rem;
  display: flex;
  flex-direction: column;

  &-Content {
    width: $contentWidth;
    margin: 0 auto;

    a:hover,
    a:active {
      color: darken($blue, 20%);
    }
  }
}

@media only screen and (max-width: 800px) {
  html {
    font-size: 50%;
  }
  .Header,
  .App-Content {
    padding-right: 2rem;
    padding-left: 2rem;
    width: 100%;
  }
}

