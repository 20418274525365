@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Condensed:wght@700&display=swap');
@import './../../assets/sass/colors';


header {
  font-size: 2.4rem;
}

nav {
  font-size: 2.4rem;
}

h1, h2, p, figure {
  margin-bottom: 2.4rem;
}

h1, h2 {
  font-family: 'Roboto Condensed', sans-serif;
}

h1 {
  font-size: 5rem;
}

h2 {
  font-size: 3rem;
}

figure {
  img {
    display: block;
  }

  figcaption {
    font-size: 1.4rem;
    background: $lightGray;
    padding: 1rem;
  }
}

p {
  font-size: 2.4rem;
  max-width: 60rem;
}

ul {
  list-style-position: inside;
  margin-bottom: 2.4rem;
  padding-left: 0;

  li {
    padding-left: 0;
    margin-left: 0;
  }
}

dl {
  font-size: 2.4rem;

  div {
    margin-bottom: 2.4rem;
  }
}

footer {
  font-size: 1.6rem;
}