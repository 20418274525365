@import url(https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Condensed:wght@700&display=swap);
.flexRow{display:flex;justify-content:space-between}.flexRow--2>*{width:48%}.flexRow--wrap{flex-wrap:wrap}.flexRow{display:flex;justify-content:space-between}.flexRow--2>*{width:48%}.flexRow--wrap{flex-wrap:wrap}.Header{height:7rem;border-bottom:1px solid #999;background:#f2f2f2;position:fixed;top:0;right:0;left:0;font-family:'Roboto Condensed';z-index:1}.Header-Nav{width:800px;margin:0 auto;display:flex;height:100%;align-items:center}.Header-Nav ul{margin:0;padding:0;list-style-type:none;display:flex}.Header-Nav-Item a:link,.Header-Nav-Item a:visited{display:inline-block;padding:1rem;text-decoration:none}.Header-Nav-Item a:hover,.Header-Nav-Item a:active{background:#114E78;color:white;border-radius:0.4rem}.Header-Nav-Item--Home{margin-right:auto;margin-left:0;font-weight:bold;font-size:3rem;padding:0rem;position:relative;left:-1rem}.Header-Nav-Item--Home a:hover,.Header-Nav-Item--Home a:active{color:#114E78;background:transparent}.Header-Nav-Item--mobileOnly{display:none}.Header .BurgerMenu{display:flex;align-items:center}.Header .BurgerMenu ul{position:fixed;z-index:1;top:0;right:0;left:0;bottom:0;background:#114E78;flex-direction:column;padding-top:7rem}.Header .BurgerMenu ul a{display:block;color:white;border-radius:0;font-size:4rem;padding-left:4rem}.Header .BurgerMenu ul a:hover{background:rgba(255,255,255,0.75);color:#114E78}.Header .BurgerMenu ul .Header-Nav-Item--mobileOnly{display:block}.Header .BurgerMenu-Trigger,.Header .BurgerMenu-Close{position:fixed;z-index:2;top:1.5rem;right:1rem;width:4rem;height:4rem;background:transparent;border:0;outline:0}.Header .BurgerMenu-Trigger img,.Header .BurgerMenu-Close img{width:100%;height:100%;opacity:0.9}@media only screen and (max-width: 800px){.Header .Header-Nav{padding-right:0;padding-left:0}}

.flexRow{display:flex;justify-content:space-between}.flexRow--2>*{width:48%}.flexRow--wrap{flex-wrap:wrap}.Footer{margin:auto auto 0 auto;padding:2rem;color:#999}

.Home span{display:inline-block;margin-right:1rem}.Home nav ul{list-style-type:none}.Home nav li{margin-bottom:1rem}.Home nav a:link{text-decoration:none;font-weight:bold}.Home .waves{-webkit-animation-name:wave-animation;animation-name:wave-animation;-webkit-animation-duration:2.5s;animation-duration:2.5s;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;transform-origin:70% 70%;display:inline-block}@-webkit-keyframes wave-animation{0%{transform:rotate(0deg)}10%{transform:rotate(14deg)}20%{transform:rotate(-8deg)}30%{transform:rotate(14deg)}40%{transform:rotate(-4deg)}50%{transform:rotate(10deg)}60%{transform:rotate(0deg)}100%{transform:rotate(0deg)}}@keyframes wave-animation{0%{transform:rotate(0deg)}10%{transform:rotate(14deg)}20%{transform:rotate(-8deg)}30%{transform:rotate(14deg)}40%{transform:rotate(-4deg)}50%{transform:rotate(10deg)}60%{transform:rotate(0deg)}100%{transform:rotate(0deg)}}


.flexRow{display:flex;justify-content:space-between}.flexRow--2>*{width:48%}.flexRow--wrap{flex-wrap:wrap}.Projects-Project{border-bottom:2px solid #eee;margin-bottom:2.4rem;padding-top:4.8rem}.Projects-Project:last-child{border-bottom:0}.Projects-Project img,.Projects-Project iframe{width:100%;max-width:100%;z-index:0}.Projects-Project .video{position:relative;width:100%;padding-bottom:56.25%}.Projects-Project .video iframe{position:absolute;top:0;left:0;width:100%;height:100%;border:0}.Projects-Project figure{background:#eee;border:1px solid #eee}

.Contact-Methods-Method{margin-bottom:0}.Contact-Methods-Method dt{display:none}.Contact-Methods-Method a:link,.Contact-Methods-Method a:visited{margin-left:-0.8rem;display:inline-block;padding-right:1rem;padding-left:5rem;line-height:6rem}.Contact-Methods-Method a:hover{background-color:#eee;border-radius:0.6rem}.Contact-Methods-Method--Email a{background:url(/static/media/gmail.23a7dee5.svg) 1rem 50% no-repeat;background-size:3rem}.Contact-Methods-Method--LinkedIn a{background:url(/static/media/linkedin.c0c32ea6.svg) 1rem 50% no-repeat;background-size:3rem}


header{font-size:2.4rem}nav{font-size:2.4rem}h1,h2,p,figure{margin-bottom:2.4rem}h1,h2{font-family:'Roboto Condensed', sans-serif}h1{font-size:5rem}h2{font-size:3rem}figure img{display:block}figure figcaption{font-size:1.4rem;background:#eee;padding:1rem}p{font-size:2.4rem;max-width:60rem}ul{list-style-position:inside;margin-bottom:2.4rem;padding-left:0}ul li{padding-left:0;margin-left:0}dl{font-size:2.4rem}dl div{margin-bottom:2.4rem}footer{font-size:1.6rem}.flexRow{display:flex;justify-content:space-between}.flexRow--2>*{width:48%}.flexRow--wrap{flex-wrap:wrap}html{font-size:62.5%}*{box-sizing:border-box}body{font-family:'Roboto', sans-serif;color:#111}*{box-sizing:border-box;padding:0;margin:0}a:link,a:visited{color:#114E78}a:hover,a:active{color:#0b314b}.App{font-size:1rem;min-height:100vh;padding-top:10rem;display:flex;flex-direction:column}.App-Content{width:800px;margin:0 auto}.App-Content a:hover,.App-Content a:active{color:#04141f}@media only screen and (max-width: 800px){html{font-size:50%}.Header,.App-Content{padding-right:2rem;padding-left:2rem;width:100%}}

