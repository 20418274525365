$basePadding: 1rem;
$contentWidth: 800px;
$stdMargin: 2.4rem;

.flexRow {
  display: flex;
  justify-content: space-between;

  &--2 {
    & > * {
      width: 48%;
    }
  }

  &--wrap {
    flex-wrap: wrap;
  }
}

